export const environment: Environment = {
  production: true,
  name: "staging",
  release: "<$release>",
  apiPath: "https://wow-api-staging.services.coara.xyz",
  webBuilderIframe: "https://account.wowfamily.de/iframe",
  adminUsername: "admin@wowfamily.de",
  adminPassword: "KLullZrnoU9uaSs7OcMGNZWj",
  bucket: "de-wowfamily-app-staging",
  firebaseConfig: {
    apiKey: "AIzaSyC5BEeVZcfZ02QPI8RfyyPshU9_tOtxJ_U",
    authDomain: "wow-customers-app.firebaseapp.com",
    projectId: "wow-customers-app",
    storageBucket: "wow-customers-app.appspot.com",
    messagingSenderId: "56424744392",
    appId: "1:56424744392:web:504f1273678bfb1acf2cfd",
    measurementId: "G-366033V6RQ",
  },
};
