export const environment: Environment = {
  production: true,
  name: "production",
  release: "0.1.7#782",
  apiPath: "https://wow-api.services.coara.xyz",
  webBuilderIframe: "https://account.wowfamily.de/iframe",
  adminUsername: "admin@wowfamily.de",
  adminPassword: "KLullZrnoU9uaSs7OcMGNZWj",
  bucket: "de-wowfamily-app",
  firebaseConfig: {
    apiKey: "AIzaSyC5BEeVZcfZ02QPI8RfyyPshU9_tOtxJ_U",
    authDomain: "wow-customers-app.firebaseapp.com",
    projectId: "wow-customers-app",
    storageBucket: "wow-customers-app.appspot.com",
    messagingSenderId: "56424744392",
    appId: "1:56424744392:web:5359ca1941f76288cf2cfd",
    measurementId: "G-8WWKN1BCD2",
  },
};
